<template>
  <div class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <Header title="Create a new event"/>

      <div v-if="user">
        <v-card-title class="subtitle">Step 1: Name your event</v-card-title>
        <v-card-text>      
          <v-alert type="info" class="mb-16">
            <p>This wizard is to <strong>create a new event</strong> and configure and manage this event.</p>
            <p>Are you a participant of an <strong>existing event</strong>? Please look in your mailbox for the invite email (also check your junk/spam folder) and do <i>not</i> create a new event.</p>
          </v-alert>
          <v-alert v-if="error" type="error">{{error}}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field 
              v-model="name" 
              :rules="nameRules" 
              autofocus
              label="How would you like to name your event? (required)" 
              prepend-icon="fa-pencil-alt"
              required>
            </v-text-field>

            <h3>Select your Event Type (optional)</h3>
            <p>How can you best describe the type of this event? Skip this question when you're not sure (yet).</p>
            <v-btn-toggle v-model="type" class="scoring-group">
              <v-btn value="CHALLENGE">
                <v-icon x-large color="primary">fa fa-watch-fitness</v-icon>
                <span>(Virtual)</span>
                <strong>Challenge</strong>
              </v-btn>
              <v-btn v-if="tenant.id !== 'cofi'" value="HYBRID">
                <v-icon x-large color="primary">fa fa-bullseye</v-icon>
                <strong>Hybrid Event</strong>
                <span>(Challenge &amp;</span>
                <span>In-Person)</span>
              </v-btn>
              <v-btn v-if="tenant.id !== 'cofi'" value="RESULTS">
                <v-icon x-large color="primary">fa fa-flag-checkered</v-icon>
                <span>(In-Person)</span>
                <strong>Race Results</strong>
              </v-btn>
              <v-btn value="STEPS">
                <v-icon x-large color="primary">fa fa-shoe-prints</v-icon>
                <strong>Step</strong>
                <span>Challenge</span>
              </v-btn>
              <v-btn value="_">
                <v-icon x-large color="primary">fa fa-ellipsis-h</v-icon>
                <strong>Other /</strong>
                <span>Don't know</span>
              </v-btn>
            </v-btn-toggle>
            <p v-if="type === 'CHALLENGE'">A (virtual) challenge which is based on fitness activities.</p>
            <p v-else-if="type === 'HYBRID'">A combination of an in-person event with a virtual component added to it.</p>
            <p v-else-if="type === 'RESULTS'">An in-person race for which race day results may be uploaded.</p>
            <p v-else-if="type === 'STEPS'">Step challenges are based on the daily total steps, regardless of whether this was during an activity or just walking around.</p>

            <div v-if="tenant.isDefault">
              <h3 class="mt-4">App Configuration (optional)</h3>
              <p>
                Would you like to enable free integration with <router-link :to="{name: 'get'}">our iPhone and Android app</router-link>? 
                Interested in having a custom-made app for your event? <router-link :to="{name: 'contact'}">Contact us</router-link> to discuss possibilities.
              </p>
              <v-switch v-model="enableApp" label="Enable app" persistent-hint hint="Enable to allow participants to use our app." />
            </div>

            <v-btn class="mt-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Create event</v-btn>
            <v-btn text v-if="$store.state.context === 'microsoft-teams'" to="/apps/teams/content">Back</v-btn>

          </v-form>
        </v-card-text>   
      </div>    
      <div v-else>
        <div v-if="tenant.isDefault">
          <v-card-title class="subtitle">Your virtual event on {{tenant.name}}?</v-card-title>
          <v-card-text>
            <p>Want to feature your (virtual) event or challenge on {{tenant.name}}? We support a wide range of features to build unique virtual experiences.</p>
            <ul class="mb-4">
              <li>One click join for participants using Strava, Garmin, Polar, Fitbit and more</li>
              <li>Badges and virtual course maps</li>
              <li>Individual and team rankings</li>
              <li>8 different challenge modes: best result, total distance, total elevation and many more</li>
              <li><router-link to="/contact">Free for small events, simple and fair pricing thereafter</router-link></li>
            </ul>
            <v-btn text color="accent" class="mr-4" :to="{name: 'features' }">Features</v-btn>
            <v-btn text color="accent" :to="{name: 'contact' }">Contact us</v-btn>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-title class="subtitle">Start your event on {{tenant.name}}</v-card-title>
          <v-card-text>
            <v-alert type="info" tile>You'll need to create an account first.</v-alert>
            <p>Create your event and go live within minutes.</p>
            <ul class="mb-4">
              <li>One click join for participants using Strava, Garmin, Polar, Fitbit, and more</li>
              <li>Badges and virtual course maps</li>
              <li>Individual and team rankings</li>
              <li>Many different challenge modes: best result, total distance, total elevation, and many more</li>
              <li><router-link to="/pricing">Pricing</router-link> which scales with the size of your event</li>
            </ul>
            <v-btn text color="accent" class="mr-4" :to="{name: 'features' }">Explore all Features</v-btn>
          </v-card-text>
        </div>
        <v-card-title class="subtitle">Register or login to get started</v-card-title>
        <v-card-text>
          <p>Please Login or Register before you create your event.</p>
          <v-btn color="accent" class="mr-4" :to="{ name: 'register', query: {referral: this.$route.query.referral, redirect:'create-event' }}">Register</v-btn>
          <v-btn outlined color="accent" :to="{name: 'login', query: { referral: this.$route.query.referral, redirect:'create-event' } }">Login</v-btn>
        </v-card-text>
      </div>  
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      breadcrumbItems: [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Create Event', disabled: true },
      ],
      valid: true,
      error: null,
      timeZoneOlson: null,
      name: "",
      type: null,
      enableApp: null,
      nameRules: [
        //v => !!v || "Please enter the event name",
      ],
    };
  },
  async mounted() {
    await this.getProfile();
      this.timeZoneOlson = DateTime.now().zoneName;
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async udateAvatar() {
      await this.getProfile();
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          var response = await eventManagerService.create({
            name: this.name,
            type: this.type === '_' ? null : this.type,
            enable_app: this.enableApp,
            timezone: this.timeZoneOlson,
            license: this.$route.query.license,
            referral: this.$route.query.referral,
            group_id: this.$route.query.groupId,
            slack_channel_id: this.$route.query.slackChannel,
            teams_info: this.$route.query.teamsInfoJson ? JSON.parse(this.$route.query.teamsInfoJson) : null,
          });
          this.$analytics.event('create_event');

          this.$router.push({ name: 'eventmanagerWizard', params: { id: response.data.id } });
        }
        catch (ex) {
          this.error = ex.response.data.msg;
          //console.log('ERR', ex, ex.response);
        }
      }
    },

    async getProfile() {
      if (this.user) {
        //var response = await profileService.get();
        //this.profile = response.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  .scoring-group {
    width: 100%;
    flex-flow: row wrap; 
    button { 
      flex-grow: 1; flex-basis: 0; height: 120px !important;
      min-width: 150px !important;
      .v-btn__content {
        flex-direction: column;
        * {display: flex !important;}
        i { margin-bottom: 10px;}
      }
      //i { display: block !important; margin-bottom: 10px;}
      //.v-btn__content span { display: block !important; background: red!important; }
    }

  }
  .scoring-group::before/*, .scoring-group::after*/ {
    content: '';
    /* from https://stackoverflow.com/questions/29732575/how-to-specify-line-breaks-in-a-multi-line-flexbox-layout 
    flex-basis: 100%; height: 0; margin: 0; border: 0;
    order: 1;*/
  }
  </style>

